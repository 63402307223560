<template>
  <div class="show-case">
    <v-img
      :src="require('@/assets/botellas/tobala_medals.png')"
      class="darken-4"
      width="100%"
      contain
      fill-height
    />
  </div>
</template>

<script>

  export default {
    name: 'MedalShowcase'
  }
</script>

<style scoped>
.show-case {
  background-color: #000;
}
</style>
